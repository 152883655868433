import { FaRegTrashAlt, FaHome, FaRegHeart, FaHeart, FaHeartBroken, FaRegStar, FaStar } from "react-icons/fa"
import { SlSizeFullscreen } from "react-icons/sl"
import { MdOutlinePool, MdOutlineFactory } from "react-icons/md"
import { LuRefreshCw } from "react-icons/lu"
import { TiDocumentDelete } from "react-icons/ti"
import { TbTrashOff } from "react-icons/tb"
import { GiRadioactive } from "react-icons/gi"

import logo_bienici from './img/logo-bienici.png'
import logo_lbc from './img/logo-leboncoin.jpeg'
import logo_etreproprio from './img/logo-etreproprio.png'
import logo_seloger from './img/logo-seloger.png'

import { back_url, authorization } from './constants'

import axios from 'axios'
// import './App.css';
import React, { useState, useEffect } from 'react'

axios.defaults.headers.common['Authorization'] = authorization

const priceFormat = (price) => {
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  })
  return formatter.format(price)
}

const dateFormat = (aDate) => {
  if(typeof(aDate) == "string")
    return aDate.replace(/([0-9]+)-([0-9]+)-([0-9]+)T([0-9]+):([0-9]+).*/, "$3/$2/$1 $4:$5")
  return aDate
}

const EnergyLogo = (props) => (
  <div className={props.type + "-logo"}>
    {props.letter == null ? "?" :
      <div className={"letter letter-" + props.letter}>
        {props.letter}
      </div>
    }
  </div>
)

const SourceLogo = (props) => {
  switch (props.src) {
    case "Bien ici": return <img className="logosource" alt="bienici" src={logo_bienici} />
    case "Le Bon Coin": return <img className="logosource" alt="leboncoin" src={logo_lbc} />
    case "Etre Proprio": return <img className="logosource" alt="etreproprio" src={logo_etreproprio} />
    case "Se loger": return <img className="logosource" alt="seloger" src={logo_seloger} />
    default: return <div style={{ float: "right" }}>{props.src}</div>
  }
}

const CommentBox = (props) => {
  const [inputValue, setInputValue] = useState(props.comment)

  useEffect(() => {
    const timer = setTimeout(() => {
      if(inputValue !== props.comment) {
        props.update(inputValue)
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [inputValue])

  return (
    <div>
      <label>Commentaire: </label>
      <textarea className="comment"
        onChange={e => {
          console.log("updated")
          setInputValue(e.target.value)
        }}
        defaultValue={inputValue}
        >
      </textarea>
    </div>
  )
}

export default class Home extends React.Component {
  constructor(props) {
    super(props)

    const defaultHome = {
      city:"",
    }

    // Initializing the state 
    this.state = {
      id: props._id,
      lastUpdate: "",
      home: {...defaultHome, ...props.home},
      refreshPending: false,
    }
  }

  refresh() {
    this.setState({ refreshPending: true })
    axios.put(back_url + '/api/refresh/home', { id: this.state.home._id })
      .then(response => {
        this.setState({ refreshPending: false }, this.props.updateList())
      })
      .catch(error => {
        console.error(error);
      })
  }

  update(newElements) {
    const newHome = {
      ...this.state.home,
      ...newElements
    }
    axios.put(back_url + '/api/home', { home: newHome })
      .then(response => {
        this.setState({ home: { ...response.data } })
        this.props.updateList()
      })
      .catch(error => {
        console.error(error);
      })
  }

  render() {
    return <div key={this.state.home._id} 
      className={"homeitem" + (!this.state.home.active ? " home-inactive" : "") + (!this.props.isFav ? " home-notFav" : "")}>
      <div className="action-bar">
        {this.state.home.trash ? (
          <span>
            <TbTrashOff alt="Annuler" className="icon-button block" onClick={(e) => this.update({ trash: false })} />
            <TiDocumentDelete alt="delete" className="icon-button block" onClick={(e) => this.props.deleteAction(this.state.home._id)} />
          </span>
        ) : (
          <FaRegTrashAlt alt="Corbeille" className="icon-button block" onClick={(e) => this.update({ trash: true })} />
        )}
        {this.state.refreshPending ?
          <LuRefreshCw alt="refresh" className="icon-button block spin" /> :
          <LuRefreshCw alt="refresh" className="icon-button block" onClick={() => this.refresh()} />
        }
        {/*this.state.home.stared ?
        <FaStar alt="Favoris" className="icon-button block" onClick={() => this.update({stared: !this.state.home.stared})}/>:
        <FaRegStar alt="Favoris" className="icon-button block" onClick={() => this.update({stared: !this.state.home.stared})}/>
      */}
        {[1,2,0].map(i => (
                <div key={i} style={{textAlign: "center"}}>
                <span alt="Premier lot"
                className={"icon-button-text " + (this.state.home.lot == i ? "icon-enlighten" : "")}
                onClick={() => {
                  if(this.state.home.lot === i) {this.update({ lot: null })}
                  else {this.update({ lot: i })}
                }}
                >{i}</span>
                </div>
        ))}
      </div>
      <div className="city">
        <SourceLogo src={this.state.home.source} />
        <a target="_blank" rel="noreferrer" href={"https://www.google.com/maps/place/" + this.state.home.postalcode + "+"+ this.state.home.city.replace(' ','-')}>
        {this.state.home.postalcode}
        &nbsp;-&nbsp;
        {this.state.home.city}
        </a>
        <br/><br/>
        &nbsp;&nbsp;
        {this.state.home.radon && this.state.home.radon.split(',').map( (value) => (
            <GiRadioactive className={ "radon-" + value } key={{value}} />
          ))}
        {this.state.home.ceveso && <span>
          &nbsp;&nbsp;<MdOutlineFactory style={{color: "red"}}/>
          </span>}
        <br /><br /><br />
        <small>
          {this.state.home.annonceur}
        </small>
      </div>
      <img alt="maison" className="photo" src={back_url + "/img/" + this.state.home.img} />
      <div className="description">
        {!this.state.home.active && <div style={{ color: "red" }}>Annonce plus disponible</div>}
        {this.props.isFav && <FaStar style={{color: "orange",float:"right", "marginRight": "10px"}}/>}
        <div className="price">{priceFormat(this.state.home.price)}</div>
        <div><small><small>
        {dateFormat(this.state.home.createdDate)}
        &nbsp;-&nbsp;
        {dateFormat(this.state.home.lastUpdate)}
        </small></small></div>
        <div style={{ clear: "both" }}></div>


        <a target="_blank" rel="noreferrer" href={this.state.home.url}>{this.state.home.title}</a>
        <div>
          <FaHome /> {this.state.home.surf_maison}
        </div>
        <div>
          <SlSizeFullscreen /> {this.state.home.surf_terrain}
        </div>
        <div>
          {this.state.home.pieces} pièces
        </div>
        {this.state.home.piscine && <MdOutlinePool />}
        <div>
          <EnergyLogo type="energie" letter={this.state.home.energie} />
          &nbsp;&nbsp;|&nbsp;&nbsp;
          <EnergyLogo type="ges" letter={this.state.home.ges} />
        </div>
        <div>
          {[...Array(5).keys()].map(note =>
            parseInt(this.state.home.hearts) > note ?
              <FaHeart key={note} className="heart-icon" onClick={() => this.update({ hearts: (note + 1) })} />
              :
              <FaRegHeart key={note} className="heart-icon" onClick={() => this.update({ hearts: (note + 1) })} />
          )}
          <FaHeartBroken className="heart-icon" style={{ display: "inline-block", marginLeft: "20px" }}
            onClick={() => this.update({ hearts: -1 })}
          />
        </div>
        <CommentBox
            comment={this.state.home.comment}
            update={(inputValue) => this.update({comment: inputValue})}
        />
      </div>
    </div>
  }
}
