// import './App.css';

import React from 'react'
import axios from 'axios'
import Task from './Task'

import { back_url, authorization } from "./constants"

import { LuRefreshCw } from "react-icons/lu"

axios.defaults.headers.common['Authorization'] = authorization

export default class TaskList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tasks: [],
      isTaskOnGoing: false,
      refresh: "",
      addHomeUrl: "",
      addType: "addOnly", // addOrUpdate" "addOnly
      resetFav: "",
      notifications: [],
    }
  }
  componentDidMount() {
    this.updateList()
    this.updateNotifications()
  }

  updateList() {
    axios.get(back_url + '/api/task')
      .then(response => {
        let tasks = response.data.tasks.sort((a, b) => {
          return a.createdDate > b.createdDate
        })
        const isTaskOnGoing = response.data.taskOnGoing
        this.setState({
          tasks: tasks,
          isTaskOnGoing: isTaskOnGoing,
        })

      })
      .catch(error => {
        console.error(error);
      });
  }

  updateNotifications() {
    axios.get(back_url + '/api/notification')
      .then(response => {
        let notifications = response.data.sort((a, b) => {
          return a.date < b.date
        }).splice(0,50)
        this.setState({
          notifications: notifications
        })
      })
      .catch(error => {
        console.error(error);
      });
  }

  processTasks() {
    axios.post(back_url + '/api/process/tasks')
  }

  refreshAll() {
    axios.post(back_url + "/api/process/refreshall")
      .then(response => {
        this.updateList()
      })
  }

  deleteSuccess() {
    axios.post(back_url + '/api/process/deletesuccesstasks')
      .then(response => {
        this.updateList()
      })
      .catch(error => {
        console.error(error);
      })
  }
  deleteAll() {
    axios.post(back_url + '/api/process/deletealltasks')
      .then(response => {
        this.updateList()
      })
      .catch(error => {
        console.error(error);
      })
  }

  refreshFavs(source) {
    axios.get(back_url + '/api/sources/refresh/' + source)
      .then(response => this.setState({ refresh: "" }, this.updateList()))
  }

  addTaskAction() {
    if (this.state.addHomeUrl !== "") {
      axios.post(back_url + '/api/home', {
        url: this.state.addHomeUrl,
        type: this.state.addType,
        resetFav: this.state.resetFav,
      })
        .then(response => {
          this.setState({ addHomeUrl: "", resetFav: "" }, this.updateList())
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  delete(id) {
    axios.delete(back_url + '/api/task/' + id)
      .then(() => this.updateList())
  }

  render() {
    return (
      <div>
        <div style={{ position: "fixed" }}>
          <button onClick={() => this.refreshFavs("etreproprio")} disabled={this.state.refresh !== ""}>
            {this.state.refresh === "etreproprio" && <span><LuRefreshCw class="spin" />&nbsp;&nbsp;</span>}
            Refresh etre proprio
          </button>
          &nbsp;&nbsp;
          <button onClick={() => this.refreshFavs("bienici")} disabled={this.state.refresh !== ""}>
            {this.state.refresh === "bienici" && <span><LuRefreshCw class="spin" />&nbsp;&nbsp;</span>}
            Refresh bienici
          </button>
          &nbsp;&nbsp;
          <button onClick={() => this.refreshFavs("seloger")} disabled={this.state.refresh !== ""}>
            {this.state.refresh === "seloger" && <span><LuRefreshCw class="spin" />&nbsp;&nbsp;</span>}
            Refresh seloger
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={() => this.refreshAll()}>Refresh all</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={() => this.processTasks()} disabled={this.state.isTaskOnGoing}>PROCESS</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={() => this.deleteSuccess()}>SUPPR SUCCES</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button onClick={() => this.deleteAll()}>SUPPR TOUT</button>
        </div>
        <div style={{ paddingTop: "50px" }}>
          
        </div>
        <h2>Taches ({this.state.tasks.length})</h2>
        {this.state.tasks.map(item => <Task key={item._id} task={item} delete={(id) => this.delete(id)} />)}
        <div>
          <textarea onChange={(e) => this.setState({ addHomeUrl: e.target.value })} value={this.state.addHomeUrl} />
          <br />
          <select value={this.state.addType} onChange={(e) => this.setState({ addType: e.target.value })}>
            <option value="addOnly">addOnly</option>
            <option value="addOrUpdate">addOrUpdate</option>
          </select>
          <br />
          Reset favoris: <select value={this.state.resetFav} onChange={(e) => this.setState({ resetFav: e.target.value })}>
            <option value=""></option>
            <option value="Le Bon Coin">Le Bon Coin</option>
            <option value="Se loger">Se loger</option>
          </select>
          <br />
          <button onClick={(e) => this.addTaskAction()}>Ajouter</button>
        </div>
        <div>
          <h2>Notifications</h2>
          {this.state.notifications.map(n => (
            <div key={n._id}>{n.date} : {n.text}
            </div>
          ))}
        </div>
      </div>
    )
  }
}
