// import './App.css';

import React from 'react'
import axios from 'axios'

import { back_url, authorization } from "./constants"

axios.defaults.headers.common['Authorization'] = authorization

export default class TaskList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cities: [],
      searchCity: "",
      blacklistedCities: false,
    }
  }
  componentDidMount() {
    this.updateCities()
  }

  updateCities(newFilter = {}) {
    let updatedFilter = {
      searchCity: this.state.searchCity,
      blacklistedCities: this.state.blacklistedCities,
      ...newFilter,
    }
    if(Object.keys(newFilter).length > 0) {
      this.setState(updatedFilter)
    }

    let searchField = {}

    if(updatedFilter.searchCity.length > 0) {
      searchField["name"] = updatedFilter.searchCity
    }
    if(updatedFilter.blacklistedCities) {
      searchField["blacklist"] = true
    }
    axios.post(back_url + '/api/city', searchField)
      .then(response => {
        let cities = response.data
        // .sort((a, b) => {
        //   return a.createdDate > b.createdDate
        // })
        this.setState({
          cities: cities,
        })

      })
      .catch(error => {
        console.error(error)
      });
  }

  updateCity(id, newStatus) {
    console.log("id", id)
    axios.post(back_url + '/api/city/' + id, newStatus)
      .then(response => {
        this.updateCities()
      })
      .catch(error => {
        console.error(error)
      });
  }

  render() {
    return (
      <div>
        <h2>Config</h2>
        <h3>Villes</h3>
        Chercher une ville (3 lettres minimum):&nbsp;
        <input type="text" value={this.state.searchCity}
          onChange={(e) => this.updateCities({ searchCity: e.target.value })}
        />
        <div>
          <input type="checkbox"
            onClick={() => this.updateCities({ blacklistedCities: !this.state.blacklistedCities })}
            checked={this.state.blacklistedCities}
          /> Afficher les villes blacklistées
        </div>
        {this.state.cities.length > 100 ?
          <div><i>Trop de villes à afficher ({this.state.cities.length}). Merci de filtrer</i></div>
          :
          <div>
            {this.state.cities.map(c => (
              <div key={c._id}>
                {c.department} - {c.name} {c.too_far && <span>trop loin</span>} -&nbsp;
                <span onClick={() => this.updateCity(c._id, { blacklist: !c.blacklist })}>
                  {c.blacklist ? "Bloqué" : "Autorisé"}
                </span>

              </div>
            ))}
          </div>
        }
      </div>
    )
  }
}
