// import './App.css';

import React from 'react'
import axios from 'axios'
import Home from './Home'

import { MdFilterList } from "react-icons/md"
import { FaRegTrashAlt, FaRegStar } from "react-icons/fa"
import { MdOutlinePool } from "react-icons/md"
import { GiRadioactive } from "react-icons/gi"

import { back_url, authorization } from "./constants"
import {departements} from './referentiel'

axios.defaults.headers.common['Authorization'] = authorization

class TextField extends React.Component {
  constructor(props) {
    super(props)

    this.state = { text: "" }
    this.valider = props.valider
  }
  render() {
    return (
      <div>
        <input value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} />
        <button onClick={() => this.valider(this.state.text)}>Valider</button>
      </div>
    )
  }
}

const generateQueryPart = (state, filterName) => {
  const filterValue = state.filters[filterName]
  if(typeof(filterName) === "boolean" || filterName === "true" || filterName === "false")
    return filterName + "=" + encodeURIComponent(filterValue) + "&"
  return filterValue !== "tous" ? filterName + "=" + encodeURIComponent(filterValue) + "&" : ""
}

export default class HomeList extends React.Component {
  constructor(props) {
    super(props)

    const defaultFilters = {
      trash: false,
      pool: false,
      stared: false,
      source: "tous",
      region: "tous",
      city: "tous",
      minheart: "0",
      maxheart: "99",
      radon: false,
    }

    this.state = {
      totalHomes: 0,
      homes: [],
      addHomeUrl: "",
      sources: [],
      regions: [],
      cities: [],
      filters: { ...defaultFilters, ...JSON.parse(localStorage.getItem("filters")) },
      sortBy: localStorage.getItem("sortBy") || "",
      refresh: "",
      token: localStorage.getItem("token"),
      favorites: [],
    }
  }

  updateList(newStateFrag) {
    const state = {
      ...this.state,
      ...newStateFrag,
    }
    axios.get(back_url + '/api/home?'
      + generateQueryPart(state, "source")
      + generateQueryPart(state, "region")
      + generateQueryPart(state, "city")
      + generateQueryPart(state, "minheart")
      + generateQueryPart(state, "maxheart")
      + generateQueryPart(state, "trash")
      + generateQueryPart(state, "pool")
      + generateQueryPart(state, "stared")
      + generateQueryPart(state, "radon")
      + generateQueryPart(state, "lot")
    )
      .then(response => {
        const totalHomes = response.data.total
        let homes = response.data.list
        const favorites = response.data.favorites
        const sources = response.data.filters.sources
        const regions = response.data.filters.regions
        const cities = response.data.filters.cities
        if (state.sortBy !== "") {
          homes = homes.sort((a, b) => {
            switch (state.sortBy) {
              case "price":
                return ( parseInt(a.price) === parseInt(b.price)) ?
                a.city > b.city :
                parseInt(a.price) > parseInt(b.price)
              case "price-d":
                return ( parseInt(a.price) === parseInt(b.price)) ?
                a.city > b.city :
                parseInt(a.price) < parseInt(b.price)
              case "hearts":
                return a.hearts < b.hearts
              case "lastUpdate":
                return a.lastUpdate > b.lastUpdate
              case "newer":
                return a.createdDate > b.createdDate
              case "terrain-dec":
                return parseInt(a.surf_terrain) < parseInt(b.surf_terrain)
              default: return a.name > b.name
            }
          })
        }
        this.setState({
          ...state,
          totalHomes: totalHomes,
          homes: homes,
          sources: sources,
          regions: regions,
          cities: cities,
          favorites: favorites,
        })
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem("token")
          this.setState({ token: "" })
        }
        console.error(error);
      });
  }

  componentDidMount() {
    this.updateList()
  }

  deleteAction(id) {
    console.log("delete ", id)
    axios.delete(back_url + '/api/home/' + id)
      .then(() => {
        this.updateList()
      })
      .catch(error => {
        console.error(error);
      });
  }

  filterAction(newFilter) {
    const newFilters = {
      ...this.state.filters,
      ...newFilter,
    }
    localStorage.setItem("filters", JSON.stringify(newFilters));

    this.updateList({ filters: newFilters })
  }
  sortByAction(criteria) {
    localStorage.setItem("sortBy", criteria)
    this.updateList({ sortBy: criteria })
  }
  validPass(i) {
    localStorage.setItem("token", i)
  }

  render() {
    return (
      <div>
        {this.state.token === "" ? (
          <TextField valider={(i => this.validPass(i))} />
        ) : (
          <div>
            <div className="filter-banner">
              <MdFilterList />
              <div className="filter-box">
                Source :&nbsp;
                <select value={this.state.filters.source} onChange={(e) => this.filterAction({ source: e.target.value })}>
                  <option key="all" value="tous">Tous</option>
                  {this.state.sources.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="filter-box">
                | Département :&nbsp;
                <select value={this.state.filters.region} onChange={(e) => this.filterAction({ region: e.target.value })}>
                  <option key="all" value="tous">Tous</option>
                  {this.state.regions.map(item => (
                    <option key={item} value={item}>{item} - {departements[item]}</option>
                  ))}
                </select>
              </div>
              <div className="filter-box">
                | Ville :&nbsp;
                <select value={this.state.filters.city} onChange={(e) => this.filterAction({ city: e.target.value })}>
                  <option key="all" value="tous">Tous</option>
                  {this.state.cities.map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="filter-box">
                | Coeurs entre :&nbsp;
                <select value={this.state.filters.minheart} onChange={(e) => this.filterAction({ minheart: e.target.value })}>
                  <option value="-1">-1</option>
                  {([...Array(6).keys()]).map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select> et &nbsp;
                <select value={this.state.filters.maxheart} onChange={(e) => this.filterAction({ maxheart: e.target.value })}>
                  {([...Array(6).keys()]).map(item => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </select>
              </div>
              | <MdOutlinePool alt="piscine"
                className={"icon-button " + (this.state.filters.pool ? "icon-enlighten" : "")}
                onClick={() => this.filterAction({ pool: !this.state.filters.pool })}
              />

              | <GiRadioactive alt="Radon(1-2)"
                className={"icon-button " + (this.state.filters.radon ? "icon-enlighten" : "")}
                onClick={() => this.filterAction({ radon: !this.state.filters.radon })}
              />

              | <FaRegTrashAlt alt="Corbeille"
                className={"icon-button " + (this.state.filters.trash ? "icon-enlighten" : "")}
                onClick={() => this.filterAction({ trash: !this.state.filters.trash })} />

              | <FaRegStar alt="favoris"
                className={"icon-button " + (this.state.filters.stared ? "icon-enlighten" : "")}
                onClick={() => this.filterAction({ stared: !this.state.filters.stared })}
              />
              {[1,2,0, 'SN'].map(i => (
                <span key={i}>
                | <span alt="Premier lot"
                className={"icon-button-text " + (this.state.filters.lot == i ? "icon-enlighten" : "")}
                onClick={() => {
                  if(this.state.filters.lot === i) {this.filterAction({ lot: null })}
                  else{this.filterAction({ lot: i })}
                }}
                >{i}</span>
                </span>
              ))}
            </div>

            <div className="sort-banner">
              Trier par :&nbsp;
              <select value={this.state.sortBy} onChange={(e) => this.sortByAction(e.target.value)}>
                <option value=""></option>
                <option value="price">Prix (-)</option>
                <option value="price-d">Prix (+)</option>
                <option value="hearts">Coeur (+)</option>
                <option value="lastUpdate">Mise à jour (+)</option>
                <option value="newer">Nouveautés (+)</option>
                <option value="terrain-dec">Terrain (+)</option>
              </select>
            </div>
            <div>
              {this.state.totalHomes !== this.state.homes.length ?
                <span> {this.state.homes.length} / </span> : ""}
              {this.state.totalHomes} Maisons
            </div>
            <div className="homelist">
              {this.state.homes.map(home => (
                <Home key={home._id} home={home}
                  deleteAction={(id) => this.deleteAction(id)}
                  updateList={() => this.updateList()}
                  isFav={this.state.favorites.includes(home.url)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}
