import './App.css';

import React from 'react'
import axios from 'axios'

import HomeList from './HomeList'
import TaskList from './TaskList'
import Config from './Config'
import {back_url, authorization} from "./constants"

import { FaHome, FaServer } from "react-icons/fa"
import { HiOutlineCog6Tooth } from "react-icons/hi2";

axios.defaults.headers.common['Authorization'] = authorization

class App extends React.Component{
  constructor(props) {
    super(props)

    // Initializing the state 
    this.state = {
      token: null,
      displayHome: localStorage.getItem("displayHome", "homes"),
      serverStatusColor: "orange",
    }
  }

  // toggleDisplay() {
  //   localStorage.setItem("displayHome", !this.state.displayHome)
  //   this.setState({displayHome: !this.state.displayHome})
  // }

  changeDisplay(newValue) {
    localStorage.setItem("displayHome", newValue)
    this.setState({displayHome: newValue})

  }


  testServer() {
    axios.get(back_url + '/api/health/').then(response => {
      this.setState({serverStatusColor: "green"})
    }).catch(err => {
      this.setState({serverStatusColor: "red"})
    })
  }

  componentDidMount() {
    this.testServer()
    this.interval = setInterval(() => this.testServer(), 5000)
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <div style={{float: "right"}}>
          <FaHome className="icon-button" onClick={() => {
              this.changeDisplay("homes")
          }}/>
          <HiOutlineCog6Tooth className="icon-button" style={{margin: "0 5px"}} onClick={() => this.changeDisplay("config")}/>
          <FaServer className="icon-button" style={{color:this.state.serverStatusColor}}
                    onClick={() => this.changeDisplay("tasks")}/>
        </div>
        <div style={{clear:'right'}}/>
        
        { this.state.displayHome === "homes"? <HomeList /> : (
            this.state.displayHome === "config" ? <Config/> :  <TaskList />
          )
        }
      </div>
    );
  }
}

export default App;
